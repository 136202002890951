import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import Masonry from "react-masonry-css"
const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  960: 2,
  767: 1,
  500: 1,
}

class Lightbox extends Component {
  state = {
    showLightbox: false,
    selectedImage: 0,
  }

  componentDidMount = () => {
    window.addEventListener("keyup", this.handleKeyUp, false)
  }

  componentWillUnmount = () => {
    window.removeEventListener("keyup", this.handleKeyUp, false)
  }

  handleClick = (e, index) => {
    e.preventDefault()
    this.setState({
      showLightbox: !this.state.showLightbox,
      selectedImage: index,
    })
  }

  closeModal = () => {
    this.setState({ showLightbox: false })
  }

  goBack = () => {
    this.setState({ selectedImage: this.state.selectedImage - 1 })
  }

  goForward = () => {
    this.setState({ selectedImage: this.state.selectedImage + 1 })
  }

  handleKeyUp = e => {
    e.preventDefault()
    const { keyCode } = e
    if (this.state.showLightbox) {
      if (keyCode === 37) {
        // Left Arrow Key
        if (this.state.selectedImage > 0) {
          this.setState({ selectedImage: this.state.selectedImage - 1 })
        }
      }
      if (keyCode === 39) {
        // Right Arrow Key
        if (this.state.selectedImage < this.props.images.length - 1) {
          this.setState({ selectedImage: this.state.selectedImage + 1 })
        }
      }
      if (keyCode === 27) {
        // Escape key
        this.setState({ showLightbox: false })
      }
    }
  }

  render() {
    const { images } = this.props
    console.log(images)
    if (images.length > 2) {
      console.log("este es el camino")
    }
    const { showLightbox, selectedImage } = this.state
    return (
      <Fragment>
        {/* <Gallery> */}
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {images.map((img, i) => (
            <GalleryItem key={img.localFile.childImageSharp.sizes.src}>
              <a
                href={img.localFile.childImageSharp.sizes.src}
                alt="Car Image"
                onClick={e => this.handleClick(e, i)}
              >
                <StyledImg sizes={img.localFile.childImageSharp.sizes} />
              </a>
            </GalleryItem>
          ))}
        </Masonry>
        {/* </Gallery> */}

        <LightboxModal
          visible={showLightbox}
          onKeyUp={e => this.handleKeyDown(e)}
        >
          {images.length > 1 && (
            <>
              <ButtonPrev onClick={this.goBack} disabled={selectedImage === 0}>
                &#8249;
              </ButtonPrev>
              <ButtonNext
                onClick={this.goForward}
                disabled={selectedImage === images.length - 1}
              >
                &#8250;
              </ButtonNext>
            </>
          )}

          <ButtonClose onClick={this.closeModal}>
            <CloseIcon />
          </ButtonClose>
          <LightboxContentBig onClick={this.closeModal}>
            <LightboxContent
            // className="__react_modal_image__modal_content"
            >
              <div>
                <LightboxItem src={images[selectedImage].source_url} />
              </div>
            </LightboxContent>
          </LightboxContentBig>
        </LightboxModal>
      </Fragment>
    )
  }
}

const StyledImg = styled(Img)`
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%; // or whatever
  & > img {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
  } */
`

const Gallery = styled.div`
  /* display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1100px) {
    grid-template-columns: repeat(5, 1fr);
  }
  grid-gap: 15px;
  .gatsby-image-outer-wrapper {
    height: 100%;
  } */
`

const GalleryItem = styled.div`
  /* position: relative; */
`
const ButtonPrev = styled.button`
  border: none;
  background: rgba(255, 255, 255, 0.9);
  display: inline-block;
  padding: 8px 8px;
  width: 32px;
  height: 32px;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 60000;
  &:disabled {
    opacity: 0.5;
  }
`
const ButtonNext = styled.button`
  border: none;
  background: rgba(255, 255, 255, 0.9);
  display: inline-block;
  padding: 8px 8px;
  width: 32px;
  height: 32px;
  font-size: 12px;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 60000;
  &:disabled {
    opacity: 0.5;
  }
`
const ButtonClose = styled.button`
  border: none;
  background: transparent;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 60000;
  &:focus {
    outline: none !important;
  }
`
const CloseIcon = styled.div`
  background: white;
  border: 1px solid #fff;
  width: 32px;
  height: 32px;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 1;
  }
  &:before,
  :after {
    position: absolute;
    content: " ";
    height: 18px;
    width: 2px;
    background-color: #333;
  }
  &::after {
    transform: rotate(-45deg);
  }
  &::before {
    transform: rotate(45deg);
  }
`

const LightboxModal = styled.div`
  position: fixed;
  z-index: 5000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  touch-action: none;
  overflow: hidden;
  opacity: ${props => (props.visible ? "1" : "0")};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
`
const LightboxContentBig = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`
const LightboxContent = styled.div``
const LightboxItem = styled.img`
  max-width: 100%;
  max-height: 100%;
  background-color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  overflow: hidden;
`

Lightbox.propTypes = {
  images: PropTypes.array.isRequired,
}

export default Lightbox
