import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Lightbox from "../components/lightbox"
import { Link } from "gatsby"
import Footer from "../components/footer"
// import ModalImage from "react-modal-image"

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  960: 2,
  767: 1,
  500: 1,
}

class Post extends Component {
  render() {
    // function Galeria(list) {
    //   if (!list) {
    //     return null
    //   }
    //   console.log(list)
    //   return (
    //     // <div>
    //     //   {list.map(item => (
    //     //     <Img fluid={item.localFile.childImageSharp.fluid} alt="" />
    //     //   ))}
    //     // </div>
    //     <div>
    //       <Masonry
    //         breakpointCols={breakpointColumnsObj}
    //         className="my-masonry-grid"
    //         columnClassName="my-masonry-grid_column"
    //       >
    //         {list.map(item => (
    //           <div>
    //             <ModalImage
    //               small={item.localFile.childImageSharp.fluid.src}
    //               large={item.source_url}
    //               hideDownload={true}
    //               // showRotate={true}
    //               // alt="Hello World!"
    //             />
    //             {/* <img src={item.localFile.childImageSharp.fluid.src} alt="" /> */}
    //             {/* <img fluid={item.localFile.childImageSharp.sizes} alt="" /> */}
    //           </div>
    //         ))}
    //       </Masonry>
    //     </div>
    //   )
    // }
    function FeaturedImage(image) {
      if (!image) {
        return null
      }
      // console.log(image)
      return (
        <div>
          <Img
            fluid={image.localFile.childImageSharp.fluid}
            alt=""
            // src={image.localFile.childImageSharp.sizes.src} alt=""
          />
        </div>
      )
    }

    const { previous, next } = this.props.pageContext
    const post = this.props.data.wordpressPost
    console.log(this.props.data)
    console.log(this.props.pageContext)
    return (
      <>
        <Layout>
          <SEO title={post.title} />

          <section className="works bgGrey ofsTop margTop ofsBottom">
            <div className="container">
              <h1>{post.title}</h1>
              {/* {FeaturedImage(post.featured_media)} */}
              <div dangerouslySetInnerHTML={{ __html: post.content }} />
              {/* {Galeria(post.acf.galeria)} */}
              {post.acf.galeria ? (
                <Lightbox images={post.acf.galeria} />
              ) : (
                <div />
              )}
              <div className="albums-nav margTop">
              <div className="one-third column tLeft">
                {previous && (
                  <Link
                    to={previous.slug}
                    className="link prev-album"
                    rel="prev"
                  >
                    ← Anterior
                  </Link>
                )}
              </div>
              <div className="one-third column tCenter">
                <h3>
                {post.title}
                </h3>
              </div>
              <div className="one-third column tRight">
                {next && (
                  <Link to={next.slug} className="link next-album" rel="next">
                    Siguiente →
                  </Link>
                )}
              </div>
            </div>
            
            </div>
            
          </section>
          <Footer />
        </Layout>
      </>
    )
  }
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default Post

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      featured_media {
        source_url
        localFile {
          childImageSharp {
            fluid(maxWidth: 200, maxHeight: 250) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 200, height: 200) {
              src
            }
          }
        }
      }
      acf {
        galeria {
          source_url
          localFile {
            childImageSharp {
              sizes(maxWidth: 400) {
                ...GatsbyImageSharpSizes
              }
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
              resize(width: 200) {
                src
              }
            }
          }
        }
      }
    }
  }
`
